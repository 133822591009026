<template>
  <div class="Service">
    <div class="services-top">
      <div class="services-top__header">
        <h1>What we do</h1>
        <div class="work-top__line"></div>
        <div class="services-top__line"></div>
      </div>
      <div class="services-top__images-container">
        <img src="@/assets/home/webdevelopment.jpg" />
      </div>
    </div>

    <div class="whatwedo">
      <div
        class="todo_container"
        :class="whatwedo.id != 1 ? 'margintop' : ''"
        v-for="whatwedo in whatwedos"
        :key="whatwedo.id"
      >
        <h1 class="number">
          {{ minimum >= whatwedo.id ? `0${whatwedo.id}` : whatwedo.id }}
        </h1>
        <div class="todo_container__text">
          <h3>{{ whatwedo.title }}</h3>
          <p>{{ whatwedo.descriptions }}</p>
          <router-link
            :to="{
              name: 'Detail',
              params: {
                descriptions: whatwedo.descriptions,
                title: whatwedo.title,
                image: '@/assets/home/' + whatwedo.image,
              },
            }"
            class="todo_link1"
            >Read More</router-link
          >
        </div>
        <div class="todo_container__image">
          <div
            class="colored__div"
            :style="{ backgroundColor: whatwedo.color }"
          ></div>
          <img :src="require('@/assets/home/works/' + whatwedo.image)" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// import LayoutDefault from "../layouts/LayoutDefault.vue";
import WhatWeDo from "@/database/whatwedo.json";
export default {
  name: `Service`,
  components: {},
  created() {
    // this.$emit(`update:layout`, LayoutDefault);
  },
  data() {
    return {
      minimum: 9,
      whatwedos: WhatWeDo,
    };
  },
  mounted() {
    window.scrollTo(0, 0);
  },
};
</script>
<style lang="scss">
.services-top {
  display: flex;
  justify-content: space-between;
  position: relative;
  align-items: center;
  margin-top: 2rem;
  height: 50rem;
  @include respond(phone) {
    flex-direction: column-reverse;
    margin-top: 10rem;
  }
  &__header {
    height: 15%;
    @include respond(phone) {
      margin-top: 10rem;
    }
    h1 {
      text-transform: uppercase;
      font-size: 5rem;
      color: $color-white;
      font-weight: bolder;
      margin-bottom: 4rem;
    }
    .work-top__line {
      position: relative;
      width: 100%;
      height: 100%;
      &::before {
        content: "";
        height: 100%;
        background: $color-white;
        width: 0.5%;
        @include absCenter;
      }
    }
  }
  &__images-container {
    width: 50%;
    height: 100%;
    position: relative;
    @include respond(phone) {
      width: 100%;
    }
    img {
      max-height: 100%;
      max-width: 100%;
      min-width: 80%;
      object-fit: cover;
      width: auto;
      height: auto;
      position: absolute;
      right: 1.8rem;
      @include respond(phone) {
        width: 100%;
        height: 100%;
      }
    }
    &::before {
      content: "";
      position: absolute;
      height: 100%;
      width: 80%;
      background-color: #00b3a2;
      z-index: -1;
      bottom: -1.3rem;
      right: 0;
    }
    //   background-image: url('../assets/home/webdevelopment.jpg');
    //   background-size: cover;
    //   background-repeat: no-repeat;
  }
}

.whatwedo {
  margin-top: 20rem;
  margin-bottom: 39rem;
}
</style>