var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"Service"},[_vm._m(0),_c('div',{staticClass:"whatwedo"},_vm._l((_vm.whatwedos),function(whatwedo){return _c('div',{key:whatwedo.id,staticClass:"todo_container",class:whatwedo.id != 1 ? 'margintop' : ''},[_c('h1',{staticClass:"number"},[_vm._v(" "+_vm._s(_vm.minimum >= whatwedo.id ? ("0" + (whatwedo.id)) : whatwedo.id)+" ")]),_c('div',{staticClass:"todo_container__text"},[_c('h3',[_vm._v(_vm._s(whatwedo.title))]),_c('p',[_vm._v(_vm._s(whatwedo.descriptions))]),_c('router-link',{staticClass:"todo_link1",attrs:{"to":{
            name: 'Detail',
            params: {
              descriptions: whatwedo.descriptions,
              title: whatwedo.title,
              image: '@/assets/home/' + whatwedo.image,
            },
          }}},[_vm._v("Read More")])],1),_c('div',{staticClass:"todo_container__image"},[_c('div',{staticClass:"colored__div",style:({ backgroundColor: whatwedo.color })}),_c('img',{attrs:{"src":require('@/assets/home/works/' + whatwedo.image)}})])])}),0)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"services-top"},[_c('div',{staticClass:"services-top__header"},[_c('h1',[_vm._v("What we do")]),_c('div',{staticClass:"work-top__line"}),_c('div',{staticClass:"services-top__line"})]),_c('div',{staticClass:"services-top__images-container"},[_c('img',{attrs:{"src":require("@/assets/home/webdevelopment.jpg")}})])])}]

export { render, staticRenderFns }